import { utils } from 'ethers/lib'
import IFewWrappedTokenJSON from '../abis/FewWrappedToken.json'
import { FEW_FACTORY_ADDRESS } from '../constants'

export const computeFewWrappedTokenAddress = (token?: string): string => {
  const constructorArgumentsEncoded = utils.defaultAbiCoder.encode(['address'], [token])
  const create2Inputs = [
    '0xff',
    FEW_FACTORY_ADDRESS,
    // salt
    utils.keccak256(constructorArgumentsEncoded),
    // init code. bytecode + constructor arguments
    utils.keccak256(IFewWrappedTokenJSON.bytecode),
  ]
  const sanitizedInputs = `0x${create2Inputs.map((i) => i.slice(2)).join('')}`
  return utils.getAddress(`0x${utils.keccak256(sanitizedInputs).slice(-40)}`)
}
